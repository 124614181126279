<template>

<app-item route="Location" :id="item.id" id-name="location" :selected="selected" v-on:shiftclick="$emit('shiftclick')">

	<app-item-badge :image="item.logo" :flag="item.flag" />

	<app-item-title :text="$highlight(item.title, highlight)" />

	<app-item-count route="Location.Groups" :params="{location: item.id}" tooltip="View groups" :count="useCount" />

</app-item>

</template>

<script>

export default {

	props: ['item', 'highlight', 'selected'],

	computed: {
	
		useCount: function() {

			var textIndex = this.$_.pluck(this.$_.where(this.$store.getters['texts'], {
				'location': this.item.id
			}), 'id')

			var matchingGroups = this.$_.filter(this.$store.getters['groups'], function(group) {

				return this.$_.intersection(group.texts, textIndex).length > 0

			}.bind(this))

			return matchingGroups.length
		
		}
	
	}

}

</script>

<style scoped>

</style>
